import { StepOptions } from 'shepherd.js'

const builtInButtons = {
    exit: {
        classes: 'cancel-button',
        secondary: true,
        text: 'Exit',
        type: 'cancel'
    },
    next: {
        classes: 'next-button btn btn-bankruptcydata',
        text: 'Next',
        type: 'next'
    },
    back: {
        classes: 'back-button',
        secondary: true,
        text: 'Back',
        type: 'back'
    }
}

export const newsDefaultStepOptions: StepOptions = {
    classes: 'shepherd-theme-arrows custom-default-class',
    scrollTo: false,
    cancelIcon: {
        enabled: true
    }
}

export const newsSteps: StepOptions[] = [

]